/******************
*   Header & Footer
******************/

#header{
  display: flex;
  height: 60px;
  padding: 0.5rem 1rem;
  align-items: center;
}

#logoTS { 
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px rgb(224, 224, 224) solid;
}

#logo, #logoTS { 
  font-weight: 900;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
}
#logo:hover,  #logoTS {
  color: #000000;
}

.navbar {
  padding: 0.5rem 1rem;
	background-color: white;
	border-bottom: 1px solid #534ab5;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("./assets/css/icons/menu-bars.svg");
}
.header-container {
  width: 100%;
  padding: 0 2rem 0 2rem;
  position: fixed;
}

.header-container-normal {
  transition: 0.5s;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.navbar-header {
width: 100%;
display: flex;
justify-content: space-between;

}

.navbar-header ul {
display: flex;
align-items: center;
list-style: none;
}

nav ul {
list-style: none;
}

.navbar a {
	color: black;
}
.navbar a:hover {
	color: #534ab5;
}

.navbar ul li:not(:last-child) {
    margin-right: 2rem;
}

body {
	margin-bottom: 0px!important;
}

.site-footer {
	background-color: #534ab5;
	color: white;
    padding:1.5rem;
}

.site-footer p {
	margin-bottom: 0px!important;
}

.fa-twitter, .fa-facebook-f, .fa-pinterest-p, .site-footer a {
	color: white;
}

.btn-twitter i, .btn-facebook i, .btn-pinterest i {
	height: 2rem;
	width: 2rem;
	line-height: 2rem;
}

.btn-twitter i:hover {
	background-color: rgb(29, 161, 242)!important;
	border-radius: 50%;
}
.btn-facebook i:hover {
	background-color: #3b5998 !important;
	border-radius: 50%;
}

.btn-pinterest i:hover {
	background-color: #e60023 !important;
	border-radius: 50%;
}
.site-footer a:hover {
        color: rgb(245, 119, 56);
}

.nav .fa-twitter, .nav .fa-facebook-f, .nav .fa-pinterest-p {
	color: #265889;
}

.nav .fa-twitter:hover, .nav .fa-facebook-f:hover, .nav .fa-pinterest-p:hover {
	color: white;
}

.nav .btn-twitter, .nav .btn-facebook, .nav .btn-pinterest {
	text-align: center;
}

.navbar-collapse {
	line-height: 2rem;
}


.link-header {
	font-weight: 700;
	color: #534ab5!important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
}
@media (min-width: 992px) {
  .navbar-expand-lg {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
      display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox!important;
      display: flex!important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
  }
}
.collapse:not(.show) {
  display: none;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
  }
}
.ml-auto, .mx-auto {
  margin-left: auto!important;
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}


a, a:visited {
  text-decoration: none;
  background-color: transparent;
  color: #534ab5;
}

/******************/

/**********************
      Title
***************/

h1 {
  color: #534ab5;
  font-size: 2.5rem;
  text-align: center;
  margin-top:26.8px;
  margin-bottom:.5rem;
  padding-top: 3.5rem;
}

h2 {
  color: #534ab5;
  font-size: 2rem;
  text-align: center;
}
.regions h2 {
  text-align:left;
  margin: 50px;
  font-size: 1.2rem;
}
.editor-container h2 {
  font-size: 1rem;
  font-weight: bold;
}
/***************
Type maps

***********/
.regions {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.type-maps-container {
  display:flex;
  flex-wrap: wrap;
  margin: 50px;
}
.map-explorer {
    margin-left:1rem;
    margin-right: 1rem;
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    margin-bottom: 30px;
}
.type-maps {
    margin-left:1rem;
    margin-right: 1rem;
    width: 244px;
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    margin-bottom: 30px;
}
.type-maps:hover, .map-explorer:hover {
  cursor: pointer;
}
.type-maps img {
  height: 140px;
}
.type-maps-label {
  color: #534ab5;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

/*****************
Button

****************/
.button--icon {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}

/*****************
form

****************/
.input-group {
 
}

.input-group input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #e7eaec;
  border-radius: unset !important;
  -webkit-appearance: none;
}

.wim-btn {
  background-color: #534ab5;
  color: white;
  border: none;
  border-radius: 1.55em;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125em;
  padding: .667em 1.333em;
}

.wim-btn:hover {
  background-color: #6e61fd;
}

/*****************************/
/*          MAP               */
/************************** **/
.wim-tooltip {   
    color: #534ab5; 
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    text-align: center;			
    padding: 10px;	
    font: 12px sans-serif;		
    border-radius: 8px;		
}


/********************/
.editor-container {
    display: flex;
    justify-content: center;
}

.editor-block:not(:last-child) {
  border-right: 1px solid rgb(217, 217, 217);
  margin-bottom: 30px;
}

.editor-block {
  padding: 10px;
}




// .editor-block {
//     margin: 10px;
//     padding: 0;
//     border-radius: 0;
//     border: none;
//     box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
//     margin-bottom: 30px;
// }


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #534ab5;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #534ab5;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/********************
*       Swatch picker
*
**********************/
.swatches-picker {
  margin: auto;
}
.swatches-picker > div > div > div {
  overflow-y: unset!important;
} 

.swatches-picker > div > div:first-child {
  box-shadow: unset!important;
}


/******************************
  Export container

********************/
#export-container {
  margin-top:100px;
  width: 100%;
  text-align: center;
}

/******************************
  Footer

********************/

.footer-container{
  width: 100%;
  min-height: 105px;
  padding: 0 2rem 0 2rem;
  background: white;
  border-top: 1px solid #f0f0f0;
  max-width: 1200px; 
  margin: auto;
  color: #534ab5;
  display: flex;
  justify-content: space-between;
}


.footer-container a {
  color: inherit;
  text-decoration: none;
}

.footer-container a:hover {
  color: #D40055;
}

.footer-container p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}